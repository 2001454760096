import React from 'react';
import { LinkButton } from '../components/shared/button/button';
import Layout from '../components/layout';
import SEO from '../components/shared/seo';

const Error404Page: React.FC = () => {
  return (
    <Layout>
      <div className="flex flex-col min-h-screen">
        <SEO title="404" />
        <main className="py-12 flex-1 flex flex-col items-center justify-center">
          <h1 className="md:text-7xl  text-5xl  font-bold">404</h1>
          <LinkButton to="/" variant="outline">
            Go Back to Home
          </LinkButton>
        </main>
      </div>
    </Layout>
  );
};

export default Error404Page;
